

$(document).ready(function(msg) {

    var setAnnountMent = function(msg) {
        var $tmp_li, $tmp_text, $tmp_ul = $('.announcement .marquee ul');
        $tmp_li = $('<li/>');
        $tmp_text = $('<p/>').text(msg);
        $tmp_text.appendTo($tmp_li);
        $tmp_li.appendTo($tmp_ul);

        $('.marquee').marquee();
    }

    if (ssExtend.get('announcement')) {
        console.log('already save announcement...');
        setAnnountMent(ssExtend.get('announcement').body);
    } else {
        console.log('retrieving announcement...');
        $.ajax({
            url: '/show-latest-announcement',
            contentType: "application/json; charset=UTF-8",
            dataType: 'json',
            method: 'get',
            success: function(message) {
                ssExtend.set('announcement', message);
                setAnnountMent(ssExtend.get('announcement').body);
            },
            error: function(message) {
                console.error(message);
            }
        });
    }


    //jackpot numbers
    var $jackpot = $('.indexPageContent-jackpot > p');
    var d, jackpotAmount, displayAmount = '';

    if ($jackpot.get(0)) { // executes only at index page
        setInterval(function() {
            displayAmount = '¥';
            d = new Date();
            jackpotAmount = ((d.getTime() - 1479938242000) / 1000 * 0.68 + 121033594.00).toFixed(2).toString();
            for (var i = 0; i < jackpotAmount.length; i++) {
                if (i == 2 || i == 5) displayAmount += (jackpotAmount[i] + ',');
                else displayAmount += jackpotAmount[i];
            }
            $jackpot.html(displayAmount);
        }, 80);
    }

})
